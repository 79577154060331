import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import './ReferUserTable.css'; 

const ReferUserTable = ({ users }) => {
    const [searchTerm, setSearchTerm] = useState('');

    // Define the columns for the DataTable
    const columns = [
        {
            name: 'Id',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Username',
            selector: row => row.username,
            sortable: true,
        },
    ];

    // Function to filter users based on the search term
    const filteredUsers = users.filter(user => {
        const { username } = user;
        return (
            username.toLowerCase().includes(searchTerm.toLowerCase())

        );
    });

    return (
        <div className='user-table-container'>
            <input
                type="text"
                placeholder="Search by name, ID, email, company name..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-bar"
            />
            <DataTable
                title="Refered User List"
                columns={columns}
                data={filteredUsers}
                pagination
            />
        </div>
    );
};

export default ReferUserTable;

import React, { useEffect, useState } from 'react';
import api from '../components/api';
import './Screens.css';
import nav from '../utils/Navigation';
import { FaRegEye } from "react-icons/fa6";
import { FaEyeSlash } from "react-icons/fa6";


function ScreenRegister(props) {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [ref , setRef] = useState('')

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(prevState => !prevState);
      };

    function onChange(e) {
        const { name, value } = e.target;
        if (name === "username") {
            setUsername(value);
        }
        if (name === "password") {
            setPassword(value);
        }
        if (name === "companyName") {
            setCompanyName(value);
        }
        if (name === "email") {
            setEmail(value);
        }
        if(name === "ref"){
            setRef(value)
        }
    }

    async function onSubmit(e) {
        e.preventDefault();
        try {
            const res = await api.post("/auth/register", { username, password, company_name: companyName, email, ref: ref });
            console.log(res);
            if(!res.data.status){
              alert(res.data.msg)
              return
            } 
            props.setUserDetails(prev => ({
              ...prev,
              id: res.data.user_id,
              username: username,
              email: email,
              companyName: companyName,
              
            }))
            nav.changeScreen(props.setPageDetails, "otp")
            
        } catch (e) {
            console.error(e);
        }
    }

    function handleBackPress(){
        nav.changeScreen(props.setPageDetails , "login")
    }

    function getRefId(){
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);

        const ref = params.get('ref')
        console.log(ref);
        
        if(ref){
            setRef(ref)
        }
    }

    useEffect(() => {
        getRefId()
    },[])

    return (
        <div className="login-screen" style={{ display: props.visible ? "flex" : "none" }}>

            <button className='back-btn' onClick={() => handleBackPress()}>Back</button>
            <div className="login-container">
                <form className="login-form" onSubmit={onSubmit}>
                    <h2 className="login-title">Register</h2>
                    <input 
                        type="text"
                        className="login-input"
                        placeholder="Username"
                        name="username"
                        value={username}
                        onChange={onChange}
                        required
                    />
                    <div className='password-form' > 
                         <input 
                            type={isPasswordVisible ? "text" : "password"}
                            className="login-input"
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={onChange}
                            required
                        />
                        {isPasswordVisible ? <FaEyeSlash onClick={() => togglePasswordVisibility()} style={{marginBottom: '1rem' , padding: '10px'}} size={25} /> : <FaRegEye onClick={() => togglePasswordVisibility()} style={{marginBottom: '1rem' , padding: '10px'}} size={25} />}
                    </div>
                    
                    <input 
                        type="text"
                        className="login-input"
                        placeholder="Company Name"
                        name="companyName"
                        value={companyName}
                        onChange={onChange}
                        required
                    />
                    <input 
                        type="email"
                        className="login-input"
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={onChange}
                        required
                    />
                    <input 
                    className='login-input'
                    placeholder="Refered By"
                    name='ref'
                    value={ref}
                    onChange={onChange}
                    />
                    <button className="login-button" type="submit">Register</button>
                    <p className="register-text">Already registered? <a className="register-link" onClick={() => nav.changeScreen(props.setPageDetails, "login")}>Login Here!</a></p>
                </form>
            </div>
        </div>
    );
}

export default ScreenRegister;

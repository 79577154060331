import React, { useState } from 'react';
import api from '../components/api';
import './Screens.css';
import nav from '../utils/Navigation';

function ScreenLogin(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    function onChange(e) {
        const { name, value } = e.target;
        if (name === "username") {
            setUsername(value);
        }
        if (name === "password") {
            setPassword(value);
        }
    }

    async function onSubmit(e) {
        e.preventDefault();
        try {
            const res = await api.post("/auth/login", { username, password });
            console.log(res);
            if(!res.data.status){
                alert(res.data.msg)
                if(res.data.redirect){
                    props.setUserDetails(prev => ({
                        ...prev,
                        id: res.data.user[0].id,
                        username: res.data.user[0].username,
                        email: res.data.user[0].email,
                        companyName: res.data.user[0].company_name,
                        accessLevel: res.data.user[0].access_level,
                        ref: res.data.user[0].ref
                    }))
                    nav.changeScreen(props.setPageDetails , "otp")
                    
                }
                return
            }
            console.log(res.data);
            props.setUserDetails(prev => ({
                ...prev,
                id: res.data.user[0].id,
                username: res.data.user[0].username,
                email: res.data.user[0].email,
                companyName: res.data.user[0].company_name,
                accessLevel: res.data.user[0].access_level,
                ref: res.data.user[0].ref
            }))
            nav.changeScreen(props.setPageDetails, "gameSelect")
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className="login-screen" style={{ display: props.visible ? "flex" : "none" }}>
            <div className="login-container">
                <form className="login-form" onSubmit={onSubmit}>
                    <h2 className="login-title">Login</h2>
                    <input 
                        type="text"
                        className="login-input"
                        placeholder="Username"
                        name="username"
                        value={username}
                        onChange={onChange}
                        required
                    />
                    <input 
                        type="password"
                        className="login-input"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={onChange}
                        required
                    />
                    <button className="login-button" type="submit">Login</button>
                    <p className="register-text">Not registered yet? <a className="register-link" onClick={() => nav.changeScreen(props.setPageDetails, "register")}>Register Here!</a></p>
                </form>
            </div>
        </div>
    );
}

export default ScreenLogin;

import React, { useEffect, useState } from 'react';
import './UserPreview.css'; // Import the CSS file
import utils from '../../utils/utils';
import {createModal , closeModal} from '../../mobx/ModalInterface';
import FreeSubscriptionModal from '../../components/GenModal/Modals/FreeSubscriptionModal';
import api from '../../components/api'

function UserPreview({ selectedUser, selectedUserGames , getSelectedUserGames , ...props }) {
    const [username, setUsername] = useState(selectedUser.username || '');
    const [email, setEmail] = useState(selectedUser.email || '');
    const [companyName, setCompanyName] = useState(selectedUser.company_name || '');


    const handleSubmit = (e) => {
        e.preventDefault();
        
        console.log("Updated User Data:", {
            username,
            email,
            companyName,
        });
        
    };


    async function adminActions(action, game){
        try {
            const res = await api.post('/admin/admin_actions',{
                user_id: props.userDetails.id,
                selected_user: selectedUser,
                selected_game: game,
                action: action
            })
            if(!res.data.status){
                alert("An error has occured")
                return
            } else {
                getSelectedUserGames()
                console.log("Success");
                
            }
        } catch (e) {
            console.log(e);
            
        }
    }
 
    

    useEffect(() => { 
        
    },[])

    return (
        <div className='user-preview-container'>
            <h2>Edit User Credentials</h2>
            <div className='user-preview-top'>
                <form className='user-form' onSubmit={handleSubmit}>
                    <div className='form-group'>
                        <label>
                            Username:
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </label>
                    </div>
                    <div className='form-group'>
                        <label>
                            Email:
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </label>
                    </div>
                    <div className='form-group'>
                        <label>
                            Company Name:
                            <input
                                type="text"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                                required
                            />
                        </label>
                    </div>
                    <button type="submit" className='submit-button'>Update User</button>
                </form>
                <div className='admin-actions'>
                    <h4>Admin Actions</h4>
                    <button type='button' onClick={() => createModal(props.setModalInterface , null , null , null , <FreeSubscriptionModal {...props} selectedUser={selectedUser} />)}>Grant Free Subscription</button>
                    {selectedUser.active ?
                        <button type='button' onClick={() => createModal(props.setModalInterface , "Deactivate User" , "Are you sure you want to Deactivate user?" , [{text: "Yes" , onclick: () => alert("User has been deactivate")}])}>Deactivate User</button> :
                        <button type='button' onClick={() => createModal(props.setModalInterface , "Activate User" , "Are you sure you want to Activate user?" , [{text: "Yes" , onclick: () => alert("User has been Activate")}])}>Activate User</button>

                    }
                </div>
            </div>
            

            <h2>User Games</h2>
            <ul className='user-games-list'>
                {selectedUserGames.map((game) => (
                    <li key={game.id} className='game-item'>
                        <h4>Game Name: {game.game_name}</h4>
                        <p>Game Id: {game.id}</p>
                        <p>Type: {game.game_type}</p>
                        <p>
                            IP: 
                            <a href={game.ip} target="_blank" rel="noopener noreferrer" className='game-ip-link'>
                                {game.ip}
                            </a>
                        </p>
                        <p>Active: {game.active ? 'Yes' : 'No'}</p>
                        <p>Paid: {game.paid ? 'Yes' : 'No'}</p>
                        <p>Expiration Date: {new Date(game.expiration_datetime).toLocaleDateString()}</p>
                        <div className='game-actions'>
                            {game.active ? 
                            <button type='button' className='action-button delete-button' onClick={() => adminActions("deactive_game", game)}>Deactivate Game</button> :
                            <button type='button' className='action-button' onClick={() => adminActions("active_game", game)}>Activate Game</button>
                            }
                            <button className='action-button delete-button' onClick={() => adminActions("delete_game", game)}> Delete Game</button>
                            <button className='action-button' onClick={() => adminActions("extand_date", game)}>Extand Expiration Date</button>
                            {
                                game.paid ?
                                <button className='action-button delete-button' onClick={() => adminActions("cancel_paid", game)}> Cancel Paid</button> :
                                <button className='action-button' onClick={() => adminActions("set_paid", game)}> Set Paid</button>
                            }
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default UserPreview;

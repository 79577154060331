import React, { useEffect } from 'react';
import './SettingsManager.css'; // Importing the CSS file

function SettingsManager({ settings = {}, setSettings }) {
    const handleCheckboxChange = (e, groupName) => {
        const { name, checked } = e.target;
        setSettings(prevSettings => ({
            ...prevSettings,
            [groupName]: {
                ...prevSettings[groupName],
                value: checked
            }
        }));
    };

    const handleSubCheckboxChange = (e, groupName, subName) => {
        const { checked, type } = e.target;

        setSettings(prevSettings => {
            const updatedSettings = {
                ...prevSettings,
                [groupName]: {
                    ...prevSettings[groupName],
                    subValues: {
                        ...prevSettings[groupName].subValues,
                        [subName]: type === "checkbox" ? checked : e.target.value
                    }
                }
            };

            if (updatedSettings[groupName].subValues.mainIdMethod) {
                const subValues = updatedSettings[groupName].subValues;
                const selectedOptions = Object.keys(subValues)
                    .filter(key => key !== 'mainIdMethod' && subValues[key] === true)
                    .map(key => key);

                updatedSettings[groupName].subValues.mainIdMethod = selectedOptions;
            }

            return updatedSettings;
        });
    };

    const handleDropdownChange = (e, key) => {
        const { value } = e.target;
        const subValues = settings[key].subValues;

        const selectedOptions = Object.keys(subValues)
            .filter(subKey => subKey !== 'mainIdMethod' && subValues[subKey])
            .map(subKey => subKey);

        const newMainIdMethod = [value, ...selectedOptions.filter(option => option !== value)];

        setSettings(prevSettings => ({
            ...prevSettings,
            [key]: {
                ...prevSettings[key],
                subValues: {
                    ...prevSettings[key].subValues,
                    mainIdMethod: newMainIdMethod
                }
            }
        }));
    };

    useEffect(() => {
        console.log("Settings Phase: ", settings);
    }, [settings]);

    return (
        <div className='settings-container'>
            <h2>Game Settings</h2>
            {Object.keys(settings).map((key) => (
                <div className='section' key={key}>
                    <div className='checkbox-group'>
                        <label>
                            {settings[key].name}
                            <input 
                                type='checkbox' 
                                name={settings[key].name} 
                                checked={settings[key].value} 
                                onChange={(e) => handleCheckboxChange(e, key)} 
                            />
                        </label>

                        {settings[key].value && (
                            <div className='sub-settings'> 
                                {Object.keys(settings[key].subValues).map((subKey) => {
                                    const subValue = settings[key].subValues[subKey];

                                    return (
                                        <div key={subKey} className='sub-value-container'>
                                            <label>
                                                {subKey}
                                                {Array.isArray(subValue) ? (
                                                    <select 
                                                        name={subKey} 
                                                        value={subValue[0]}
                                                        onChange={(e) => handleDropdownChange(e, key)}
                                                    >
                                                        {subValue.map((option, index) => (
                                                            <option key={index} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                ) : (
                                                    <input 
                                                        type={typeof subValue === 'boolean' ? 'checkbox' : 'text'} 
                                                        name={subKey}
                                                        value={typeof subValue === 'boolean' ? '' : subValue}
                                                        checked={typeof subValue === 'boolean' ? subValue : undefined} 
                                                        onChange={(e) => handleSubCheckboxChange(e, key, subKey)}
                                                    />
                                                )}
                                            </label>

                                            {settings[key]?.notes[subKey] && <label className='note'>Note: {settings[key]?.notes[subKey]}</label>}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default React.memo(SettingsManager);

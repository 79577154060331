import React, { useEffect, useState } from 'react';
import './FreeSubscriptionModal.css';  // Import the CSS file
import gameSettings from '../../../const/Settings';
import api from '../../api'



function FreeSubscriptionModal({selectedUser , ...props}) {
  const [selectedGames, setSelectedGames] = useState([]);

  // Handle the change of checkboxes
  const handleCheckboxChange = (gameKey) => {
    setSelectedGames(prevSelectedGames => 
      prevSelectedGames.includes(gameKey) 
        ? prevSelectedGames.filter(key => key !== gameKey) // Remove if already selected
        : [...prevSelectedGames, gameKey] // Add if not selected
    );
  };

  async function onSubmit(){
    try {
        const res = await api.post('/admin/set_user_free_subscription',{
            user_id: props.userDetails.id,
            selected_user: selectedUser,
            selected_games: selectedGames
        })
        if(!res.data.status){
            alert(res.data.err)
            return
        } else {
            alert(`Success`)
        }
        console.log(res);
        
    } catch (e) {
        console.log(e);
        
    }
  }

  async function getUserFreeSubs(){
    try {
        const res = await api.post('/admin/get_user_free_subscription', {
            user_id: props.userDetails.id,
            selected_user: selectedUser,
        })
        if(!res.data.status){
            alert("An error has occured")
            return
        } else {
            if(Array.isArray(res.data.selectedGames)){
                setSelectedGames(res.data.selectedGames)
            }
        }
        console.log(res.data.selectedGames);
        
    } catch (e) {
        console.log(e);
        
    }
  }

  useEffect(() => {
    getUserFreeSubs()
  },[])

  return (
    <div className='free-sub-container'>
      <h2>Set Free Subscription</h2>
      <form className="checkbox-container">
        {Object.keys(gameSettings).map(gameKey => (
          <div key={gameKey} className="checkbox-item">
            <input 
              type="checkbox" 
              id={gameKey} 
              value={gameKey}
              checked={selectedGames.includes(gameKey)} // Preselect if game is in selectedGames
              onChange={() => handleCheckboxChange(gameKey)}
            />
            <label htmlFor={gameKey}>{gameKey}</label>  {/* Display the game key */}
          </div>
        ))}
      </form>

   
       <button type='button' onClick={() => onSubmit()}>Confirm</button>
     
   
    </div>
  );
}

export default FreeSubscriptionModal;

import React, { useEffect, useState } from 'react';
import './Screens.css';
import api from '../components/api'
import path from 'path-browserify';
import nav from '../utils/Navigation';
import SettingsManager from '../components/Settings/SettingsManager';
import gameSettings from '../const/Settings';
import DataCollector from '../components/DataCollector/DataCollector';

function ScreenGameDetails(props) {
    const [settings, setSettings] = useState(gameSettings[props.appDetails.currentGame]);
    
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [listOfFiles , setListOfFiles] = useState([])
    const [gameURL, setGameURL] = useState()
    const [paidPlan , setPaidPlan] = useState(false)
    const [bulkUpload , setBulkUpload] = useState(false)
    const [allowTos, setAllowTos] = useState(false)

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setSettings(prevSettings => ({
            ...prevSettings,
            [name]: checked
        }));
    };

    const handleFilesChange = (event) => {
        setSelectedFiles([...event.target.files]);
      };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const inputName = event.target.name;
    
        // Set the uploaded file name to match the input field name
        const renamedFile = new File([file], inputName, { type: file.type });
    
        setSelectedFiles((prevFiles) => ({
            ...prevFiles,
            [inputName]: renamedFile
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = new FormData()
            data.append("settings" , JSON.stringify(settings))
            Object.values(selectedFiles).forEach(file => {
                data.append('files', file);
              });
            data.append("user" , JSON.stringify(props.userDetails))
            data.append("selected_game" , props.appDetails.currentGame)
            data.append('user_game' , JSON.stringify(props.appDetails))
            const res = await api.post('/create_game', data)
            console.log(res.data);
            if(!res.data.status){
                alert(res.data.msg)
                return
            }
            if(res.data.currentGame){
                props.setAppDetails(prev => ({
                    ...prev,
                    game_id: res.data.currentGame[0].id,
                    settings: res.data.currentGame[0].settings

                }))
                setGameURL(res.data.ip)
                alert("Game created successfully")
            }
        } catch (e) {
            console.log(e);
        }
        
        // Handle form submission logic
    };

    function handleBackPress(){
        props.setAppDetails(prev => ({
            ...prev,
            game_id: null,
            settings: null,
            currentGame: null

        }))
        nav.changeScreen(props.setPageDetails , "gameSelect")
    }

    async function getGameDefaultFiles(){
        try {
             const res = await api.post("/get_game_files", 
                {
                    game_type: props.appDetails.currentGame
                }
             )
             if(res.data.status){
                setListOfFiles(res.data.files)
             }
        } catch (e) {
            console.log(e);
        }
    }

    async function downloadGameFiles() {
        try {
            const res = await api.post("/download_game_files", {
                game_type: props.appDetails.currentGame
            }, {
                responseType: 'blob' // Important to receive the response as a blob
            });
    
            // Create a URL for the blob
            const url = window.URL.createObjectURL(new Blob([res.data]));
    
            // Create a link element
            const link = document.createElement('a');
            link.href = url;
    
            // Set the download attribute to specify the filename
            link.setAttribute('download', `${props.appDetails.currentGame}_assets.zip`);
    
            // Append the link to the body (required for Firefox)
            document.body.appendChild(link);
    
            // Programmatically click the link to trigger the download
            link.click();
    
            // Clean up and remove the link
            link.parentNode.removeChild(link);
    
        } catch (e) {
            console.log(e);
        }
    }

    async function loadExistingGame(){
        if(!props.appDetails.game_id){
            return
        }
        try {
            const res =  await api.post('/get_user_game_details' , {
                user_id: props.userDetails.id,
                game_id: props.appDetails.game_id
            })
            if(!res.data.status){
                //Failure
            } else {
                setGameURL(res.data.game.ip)
                setPaidPlan(res.data.game.paid)
            }
            console.log(res.data);
            
        } catch (e) {
            
            console.log(e);
            
        }
    }

    async function createOrder(){
        try {
            const response = await api.post('/paypal/pay', {
                user_id: props.userDetails.id,
                game_id: props.appDetails.game_id,
                game_type: props.appDetails.currentGame
            })
            if(!response.data.status){
                return
            } 

            const paymentUrl = response.data.url

            window.open(paymentUrl, '_blank');
            
        } catch (e) {
            console.log(e);
            
        }
    }

    useEffect(() => {
        getGameDefaultFiles()
        console.log(gameSettings[props.appDetails.currentGame]);
    },[props.pageDetails.gameDetails])

    useEffect(() => {
        loadExistingGame()
        
    }, [props.game_id])

    return (
        <div style={{display: props.visible ? "block" : "none"}}>
            <button className='back-btn' onClick={() => handleBackPress()}>Back</button>
            <form className="game-settings-form" onSubmit={handleSubmit} >
                <h3>Game Settings</h3>
                <SettingsManager settings={settings} setSettings={setSettings} />
                <div className='tos-setting'>
                    <input type='checkbox' checked={allowTos} onChange={() => setAllowTos(perv => !perv)}></input>
                    <label>Terms & Conditons</label>
                    {allowTos ? <input type='file' onChange={handleFileChange} accept="application/pdf"  name={"toc.pdf"}></input> : null}
                   
                </div>
                <div className='user-helper'>
                    <button type='button' onClick={() => downloadGameFiles()}>Download Game Files</button>
                    <button type='button'>Link to test game</button>
                </div>
                {gameURL ? 
                <div className='url-holder'>
                     <label>URL</label>
                    {paidPlan ? 
                        <div className='url-holder'>
                            <a href={gameURL} target={"_blank"}>Click here to visit the live game</a>
                            <DataCollector {...props} />
                        </div> :
                        <div className='url-holder'>
                            <a href={`https://theminigame.com/saas/demo/${props.appDetails.currentGame}`} target={"_blank"}>Click here to visit the demo game</a>
                            <button type='button' onClick={() => createOrder()}>Buy full game</button>
                        </div>}
                        
                    
                   
                </div> : null}
                <div className="file-upload">
                <label>
                    Bulk upload
                    <input type='checkbox' checked={bulkUpload} onChange={() => setBulkUpload(prev => !prev)}></input>
                </label>
                    <label>
                        Upload Assets:
                    </label>
                    {bulkUpload ? <input type='file' onChange={handleFilesChange} multiple></input> : 
                    <div className='img-container'>
                    {listOfFiles.length > 0 ? listOfFiles.map((file , key) => {
                
                    return (
                        <div key={key} className='img-holder'>
                            {String(file).includes(".wav") || String(file).includes(".txt") ? null :  <img  src={`http://34.165.198.104:3000/${file}`} /> }
                           
                            <label>{path.basename(file)}</label>
                            <input 
                            type="file" 
                            onChange={handleFileChange} 
                            name={path.basename(file)}
                            />
                        </div>
                    )
                    }) : null}
                </div>
                    }
                    
                    
                </div>
                <button type="submit">Save Settings</button>
            </form>
        </div>
       
        
    );
}

export default ScreenGameDetails;


import React, { useEffect , useState } from 'react'
import './Screens.css'
import nav from '../utils/Navigation';
import api from '../components/api'

function ScreenOtp(props) {
    const [otp, setOtp] = useState(new Array(6).fill(''));

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return;

        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        // Focus the next input field
        if (element.nextSibling && element.value) {
            element.nextSibling.focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !otp[index] && e.target.previousSibling) {
            e.target.previousSibling.focus();
        }
    };

    const handleSubmit = async () => {
        try {
            const res = await api.post('/auth/validate', {
                user_id: props.userDetails.id,
                otp_code: otp
            })

            if(!res.data.status){
                alert(res.data.msg)
                return
            } else {
                alert("User has been activated")
                nav.changeScreen(props.setPageDetails , 'gameSelect')
            }
        } catch (e) {

        }
    };

    async function resendOtp(){

    }

    function handleBackPress(){
        nav.changeScreen(props.setPageDetails , "login")
    }

    useEffect(() => {
        console.log(props);
        
    },[])

    return (
        <div className="otp-container">
            <h2>Enter OTP</h2>
            <button className='back-btn' onClick={() => handleBackPress()}>Back</button>
            <div className="otp-input-container">
                {otp.map((data, index) => (
                    <input
                        key={index}
                        type="text"
                        maxLength="1"
                        value={data}
                        onChange={e => handleChange(e.target, index)}
                        onKeyDown={e => handleKeyDown(e, index)}
                        onFocus={e => e.target.select()}
                        className="otp-input"
                    />
                ))}
            </div>
            <div className='otp-buttons'>
                <button onClick={handleSubmit} className="otp-submit-button">
                    Verify OTP
                </button>
                <button type='button' onClick={() => resendOtp()} className="otp-submit-button">
                    Resend OTP
                </button>
            </div>
          
        </div>
    );
}

export default ScreenOtp
const nav = {
    changeScreen: function changeScreen(setPageDetails , destination){
        setPageDetails(prev => {
            const newDetails = Object.keys(prev).reduce((acc, key) => {
                acc[key] = false;
                return acc;
            }, {});
    
            newDetails[destination] = true;
    
            return newDetails;
        });
    }
    
}

export default nav